@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base{
  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

@layer components{
  .link{
    color: blue;
    text-decoration: underline;
  }
}

@layer utilities{

}